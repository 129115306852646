<template>
  <div>
    <br/>
    <br/>
    <Row type="flex" justify="center" :gutter="5">
      <Col :xs="20" :sm="18" :md="12" :lg="4">
        <Card>
          <p slot="title">
            <Icon type="ios-bookmarks"></Icon>
            留言板
          </p>
          <p>
            总会在某个不经意间，想起那些走过生命的匆匆过客。会幻想他们如今的模样，和那些彼此所曾拥有的过往。在分别的路上，有过无情，有过洒脱，但更多的是过不经意间的天各一方。啦啦，不矫情了，有想说的话就快给我说吧，需要说明的是，你的留言是不会被公开的，只有我可见，还有就是没有回复功能的。</p>
        </Card>
      </Col>
      <Col :xs="20" :sm="18" :md="12" :lg="10">
        <Card>
          <p slot="title">
            <Icon type="ios-create"/>
            说点什么把！
          </p>
          <Input type="textarea" v-model="messgae.Content" rows="6"></Input>
          <Button @click="sendMessage" type="primary" style="margin-top:10px">留言</Button>
        </Card>
      </Col>
    </Row>
  </div>
</template>


<script>
export default {
  data() {
    return {
      messgae: {
        Content: "",
        GpsLocation: ""
      }
    };
  },
  created() {
    document.title = "留言板 - 消逝的红叶-我就是我，是不一样的烟火";
  },
  methods: {
    sendMessage() {
      this.$Spin.show({
        render: h => {
          return h("div", [
            h("Icon", {
              class: "demo-spin-icon-load",
              props: {
                type: "load-c",
                size: 18
              }
            }),
            h("div", "留言中....")
          ]);
        }
      });

      var that = this;
      console.log(this.messgae);
      this.$http.post("/guestbook", this.messgae).then(
          function (response) {
            console.log(response.data);
            if (200 === response.data.code) {
              that.$Spin.hide();
              that.messgae = {
                Content: "",
                GpsLocation: ""
              };
              that.$Message.success("留言成功!");
            } else {
              that.$Message.error(response.data.msg);
            }
          },
          function () {
            //http状态码
            that.$Spin.hide();
            that.$Message.error("留言失败");
          }
      );
    }
  }
};
</script>
